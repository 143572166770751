import styled from 'styled-components';
import PlayerAlbum from 'components/playlist/player/PlayerAlbum';


const PlayerBackgroundStyled = styled.div`
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    @media (min-width: 375px) {
        grid-template-columns: repeat(3, 33.33%);
    }
    @media (min-width: 500px) {
        grid-template-columns: repeat(4, 25%);
    }
    @media (min-width: 650px) {
        grid-template-columns: repeat(5, 20%);
    }
    @media (min-width: 750px) {
        grid-template-columns: repeat(6, 16.667%);
    }
    position: absolute;
    width: 100%;
    height: 100%;
    max-height: 325px;
    z-index: -1;
    background: linear-gradient(#33353D 65%, rgba(34,35,40));

    ::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(transparent 30%, #222328);
        }
`;

const PlayerBackground = ({
    albums
}) => {
    return (
        <PlayerBackgroundStyled>
            {albums.map((album, index) => {
                return (
                    <PlayerAlbum 
                        albumArt={album}
                        key={`playerAlbum-${index}`}
                    />
                );
            })}
        </PlayerBackgroundStyled>
    );
};

export default PlayerBackground;