import {useState, useRef} from 'react';
import styled from 'styled-components';
import PlaylistBuilderContainer from 'containers/PlaylistBuilderContainer';
import Dropdown from 'components/Dropdown';
import CreatePlaylist from 'components/buttons/CreatePlaylist';
import Logo from 'components/Logo';
import Intro from 'components/home/intro';

const Header = styled.header`
    display: flex;
    justify-content: center;
    padding: 40px 0 30px;
`;

const Content = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const HomeStyled = styled.section`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const HomeContainer = () => {
    const homeContainerRef = useRef();
    const [playlistBuilderContainerOpened, setPlaylistBuilderContainerOpened] = useState(false);

    return (
        <HomeStyled ref={homeContainerRef}>
            <Header>
                <Logo/>
            </Header>
            <Content>
                <Dropdown
                    opened={playlistBuilderContainerOpened}
                    position='max'
                    maxWidth='700'
                    maxHeight='700'
                    containerRef={homeContainerRef}
                    setOpened={setPlaylistBuilderContainerOpened}
                    button={<CreatePlaylist action={() => setPlaylistBuilderContainerOpened(prev => !prev)}/>}
                    content={<PlaylistBuilderContainer action={setPlaylistBuilderContainerOpened}/>}
                />
                <Intro/>
            </Content>
        </HomeStyled>
    );
};

export default HomeContainer;