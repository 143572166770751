import { useState, memo } from 'react'
import styled from 'styled-components';
import CustomScrollbars from 'components/CustomScrollbars';
import Finished from 'components/buttons/FinishedButton';
import modalArrowIcon from 'img/modal-arrow.svg';

const SortByStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const CategoryStyled = styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 15px 0;
    border-radius: 5px;
    border-bottom: 0;
    background: #36383F;

    .buttonContainer {
        display: grid;
        grid-template-columns: 50fr 50fr;
        gap: 3vw;
        margin: 0 15px;
    }

    button {
        padding: calc(5px + 2vw) 0;
        border: 0;
        border-radius: 8px;
        font-size: .875rem;
        font-weight: 600;
        color: #9598A6;
        background: #2D2E35;
        cursor: pointer;

        &.is-active {
            background: #3C96CE;
            color: #fff;
        }
    }
`;

const TypeStyled = styled.div`
    position: relative;
    width: 100%;
    margin-top: 30px;
    padding: 15px;
    border-radius: 5px;
    border-bottom: 0;
    background: #36383F;

    ::before {
        content: '';
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-10px);
        width: 20px;
        height: 13px;
        background: url(${modalArrowIcon});
    }

    .buttonContainer {
        display: grid;
        grid-template-columns: 50fr 50fr;
        gap: 3vw
    }

    button {
        padding: calc(5px + 2vw) 0;
        border: 0;
        border-radius: 8px;
        font-size: .875rem;
        font-weight: 600;
        color: #9598A6;
        background: #2D2E35;
        cursor: pointer;

        &.is-active {
            background: #3C96CE;
            color: #fff;
        }
    }
`;

const SortBy = ({
    sort,

    setSort,
    setSortByDropdownState
}) => {
    const [pendingSortBy, setPendingSortBy] = useState(sort.sortBy);
    const [pendingSortByType, setPendingSortByType] = useState(sort.type);
    const categories = ['Title', 'Artist', 'Subreddit', 'Upvotes', 'Time'];
    const types = ['Ascending', 'Descending'];

    const finishedFn = () => {
        setSortByDropdownState(prev => !prev);
        setTimeout(() => {
            setSort({
                sortBy: pendingSortBy,
                type: pendingSortByType
            });
        }, 200);
    };

    return (
        <SortByStyled>
            <CategoryStyled>
                <CustomScrollbars
                    autoHeight={true}
                    autoHeightMax="100%"
                    style={{
                        // Fixes scrollbar not showing in safari
                        display: 'flex',
                        background: '#36383f'
                    }}
                >
                    <div className="buttonContainer">
                        {categories.map(category => (
                            <button
                                className={(category.toLowerCase() === pendingSortBy) ? 'is-active' : ''}
                                key={`category-${category}`}
                                //active={(category.toLowerCase() === pendingSortBy) ? 'true' : 'false'}
                                onClick={() => setPendingSortBy(category.toLowerCase())}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </CustomScrollbars>

            </CategoryStyled>
            <TypeStyled>
                <div className="buttonContainer">
                    {types.map(type => (
                        <button
                            key={`type-${type}`}
                            className={(type.toLowerCase() === pendingSortByType) ? 'is-active' : ''}
                            //active={(type.toLowerCase() === pendingSortByType) ? 'true' : 'false'}
                            onClick={() => setPendingSortByType(type.toLowerCase())}
                        >
                            {type}
                        </button>
                    ))}
                </div>
            </TypeStyled>
            <Finished
                text="Sort"
                active={(pendingSortByType && pendingSortBy) ? 'true' : 'false'}
                onClickFn={() => finishedFn()}
            />
        </SortByStyled>
    );
};

export default memo(SortBy);