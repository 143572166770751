import styled from 'styled-components';
import Icon from 'components/Icon';

const AddToPlaylistStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    border: 0;
    width: 40px;
    height: 36px;
    border-radius: 5px;
    background: ${({active}) => active ? '#7a7e8b' : '#666974'};
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: 200ms background ease-in;

    :hover {
        background: #7a7e8b;
    }
`;

const AddToPlaylist = ({
    active
}) => {
    return (
        <AddToPlaylistStyled
            active={active}
            aria-label="Edit Playlist" 
        >
            <Icon name="icon-playlist-edit" width="21px" color="#E7E7E7"/>
        </AddToPlaylistStyled>
    );
};

export default AddToPlaylist;