import React, { useEffect, useRef, forwardRef, useCallback, memo } from 'react';
import styled  from 'styled-components';
import TrackContainer from './TrackContainer';
import TrackPlaceholder from './TrackPlaceholder';
import { FixedSizeList as List, areEqual} from 'react-window';
import memoize from 'memoize-one';
import AutoSizer from "react-virtualized-auto-sizer";
import FadeTransition from 'components/FadeTransition';
import CustomScrollbars from 'components/CustomScrollbars';


const PlaylistStyled = styled.div`
    overflow: hidden;
    position: relative;
    flex: 1;

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, #222328 0%, rgb(34 35 40 / 0%) 100%);
        z-index: 1;
    }

    .simplebar-scrollbar::before {
        background: rgb(231 231 231 / 80%);
    }

    .simplebar-content-wrapper {
        position: relative;
    }
`;

const TracksStyled = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
`;

const Row = memo(({style, index, data}) => {
    const {tracks, activeTrack, selectActiveTrackFn, playing} = data;
    const track = tracks[index];
    const isActive = activeTrack && activeTrack.spotifyId === track.spotifyId;
    const paddingTop = 15;

    return (
        <div style={{
            ...style,
            top: `${parseFloat(style.top) + paddingTop}px`
        }}>
            <TrackContainer
                isActive={isActive}
                track={track}
                playing={playing}
                clickFn={selectActiveTrackFn}
            />
        </div>
    )
}, areEqual);

const createItemData = memoize((tracks, activeTrack, selectActiveTrackFn, playing) => ({
    tracks,
    activeTrack,
    selectActiveTrackFn,
    playing
}));

const innerElementType = forwardRef(({ style, ...rest }, ref) => {
    const paddingTop = 15;
    const paddingBottom = 150;

    return (
        <div
            ref={ref}
            style={{
            ...style,
            height: `${parseFloat(style.height) + (paddingTop + paddingBottom)}px`
        }}
            {...rest}
        />
    );
});

const CustomScrollbar = styled.div`
    scrollbar-width: thin;
    scrollbar-color: #888 #222328;

    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 3px;
        background: #888;
        background: none;
        transition: color .2s ease-in;
    }
        
    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #888;;
        transition: opacity .2s ease-in;

        :hover {
            background: #a3a3a3;
        }
    }
`;

const Playlist = ({
    tracks,
    activeTrack,
    playing,
    windowHeight,

    changeTrackFn,
    togglePlayFn
}) => {
    const playlistRef = useRef();
    const selectTrack = useCallback((track) => {
        if (!activeTrack) {
            changeTrackFn(track);
        } else {
            if (activeTrack.spotifyId === track.spotifyId) {
                console.log('toggle play');
                togglePlayFn();
            } else {
                changeTrackFn(track);
            }
        }
    }, [tracks, activeTrack, togglePlayFn]);

    useEffect(() => {
        if (playlistRef.current && activeTrack) {
            const activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);
            playlistRef.current.scrollToItem(activeTrackIndex || 0, 'center');
        }
        
    }, [tracks]);

    const itemData = createItemData(tracks, activeTrack, selectTrack, playing);

    return (
        <PlaylistStyled>

            <FadeTransition inProp={!tracks.length} timeout={500}>
                <TrackPlaceholder 
                    height={windowHeight}
                />
            </FadeTransition>
                
            <AutoSizer>
                {({width, height}) => (
                    <FadeTransition inProp={!!tracks.length} timeout={500}>
                        <TracksStyled>
                            <List
                                ref={playlistRef}
                                width={width}
                                height={height}
                                itemCount={tracks.length}
                                itemSize={114}
                                itemData={itemData}
                                overscanCount={3}
                                itemKey={index => tracks[index].spotifyId}
                                outerElementType={CustomScrollbar}
                                innerElementType={innerElementType}
                            >
                                {Row}
                            </List>
                        </TracksStyled>
                    </FadeTransition>
                )}
            </AutoSizer>
      
        </PlaylistStyled>
    );
};

export default memo(Playlist);