import React, { useEffect, useCallback, useRef, forwardRef, memo } from 'react';
import styled from 'styled-components';
import TrackContainer from './TrackContainer';
import TrackPlaceholder from './TrackPlaceholder';
import { FixedSizeList as List, areEqual } from 'react-window';
import memoize from 'memoize-one';
import AutoSizer from "react-virtualized-auto-sizer";
import FadeTransition from 'components/FadeTransition';
import Icon from 'components/Icon';


const PlaylistStyled = styled.div`
    overflow: hidden;
    position: relative;
    flex: 1;
    border-radius: 0 0 15px 15px;
    //https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
    transform: translateZ(0);

    ::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background: linear-gradient(to bottom, #222328 0%, rgb(34 35 40 / 0%) 100%);
        z-index: 1;
        pointer-events: none;
    }
`;

const PlaylistHeaderStyled = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 15px 17px 0 55px;
    padding: 0 10px 10px;
    border-bottom: 1px solid #36383F;
    color: #fff;
    font-weight: 600;

    .title, .artist, .subreddit, .votes, .time {
        flex: 1 1 33%;
        padding: 0;
        text-align: left;
        border: 0;
        font-size: 0.875em;
        font-weight: 600;
        color: #E7E7E7;
        background: none;
        cursor: pointer;

        :hover {
            color: #fff;
        }
    }

    .subreddit {
        padding-left: 24px;
    }

    .votes, .time {
        flex: 0 0 50px;
    }

    .sortByIcon {
        margin-left: 5px;
    }
`;

const TracksStyled = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
`;

const Row = memo(({ style, index, data }) => {
    const { tracks, activeTrack, selectActiveTrackFn, playing } = data;
    const track = tracks[index];
    const isActive = activeTrack && activeTrack.spotifyId === track.spotifyId;
    const paddingTop = 15;

    return (
        <div style={{
            ...style,
            top: `${parseFloat(style.top) + paddingTop}px`
        }}>
            <TrackContainer
                isActive={isActive}
                track={track}
                playing={playing}
                clickFn={selectActiveTrackFn}
                index={index}
            />
        </div>
    )
}, areEqual);

const createItemData = memoize((tracks, activeTrack, selectActiveTrackFn, playing) => ({
    tracks,
    activeTrack,
    selectActiveTrackFn,
    playing
}));

const innerElementType = forwardRef(({ style, ...rest }, ref) => {
    const paddingTop = 15;
    const paddingBottom = 150;

    return (
        <div
            ref={ref}
            style={{
                ...style,
                height: `${parseFloat(style.height) + (paddingTop + paddingBottom)}px`
            }}
            {...rest}
        />
    );
});

const CustomScrollbar = styled.div`
    scrollbar-width: thin;
    scrollbar-color: #888 #222328;

    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 3px;
        background: #888;
        background: none;
        transition: color .2s ease-in;
    }
        
    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #888;;
        transition: opacity .2s ease-in;

        :hover {
            background: #a3a3a3;
        }
    }
`;

const Playlist = ({
    tracks,
    activeTrack,
    playing,
    windowHeight,
    changeTrackFn,
    togglePlayFn,
    setSort,
    sort
}) => {
    const selectTrack = useCallback((track) => {
        if (!activeTrack) {
            changeTrackFn(track);
        } else {
            if (activeTrack.spotifyId === track.spotifyId) {
                console.log('toggle play');
                togglePlayFn();
            } else {
                changeTrackFn(track);
            }
        }
    }, [tracks, activeTrack, togglePlayFn]);
    const playlistRef = useRef();
    const itemData = createItemData(tracks, activeTrack, (track) => selectTrack(track), playing);

    const sortFn = useCallback(
        (sortBy) => {
            if (sortBy === sort.sortBy) {
                setSort(prev => ({
                    sortBy: sortBy,
                    type: (prev.type === 'ascending') ? 'descending' : 'ascending'
                }));
            } else {
                setSort(() => ({
                    sortBy: sortBy,
                    type: 'ascending'
                }));
            }
        },
        [sort, setSort]
    );

    useEffect(() => {
        if (playlistRef.current && activeTrack) {
            const activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);
            playlistRef.current.scrollToItem(activeTrackIndex || 0, 'center');
        }
    }, [tracks]);

    return (
        <>
            <PlaylistHeaderStyled>
                <button 
                    className="title"
                    onClick={() => sortFn('title')}
                    aria-label="Sort Playlist by Title" 
                >
                    Title 
                    {sort.sortBy === 'title' && 
                        <Icon
                            className='sortByIcon'
                            name={sort.type === 'ascending' ? 'icon-sort-up': 'icon-sort-down'}
                            width="13px"
                            height="13px"
                            color='#E7E7E7'
                            hover='#fff'
                            transitionMS='0'
                        />
                    }
                </button>
                <button 
                    className="artist"
                    onClick={() => sortFn('artist')}
                    aria-label="Sort Playlist by Artist" 
                >
                    Artist
                    {sort.sortBy === 'artist' && 
                        <Icon
                            className='sortByIcon'
                            name={sort.type === 'ascending' ? 'icon-sort-up': 'icon-sort-down'}
                            width="13px"
                            height="13px"
                            color='#E7E7E7'
                            hover='#fff'
                            transitionMS='0'
                        />
                    }
                </button>
                <button 
                    className="subreddit"
                    onClick={() => sortFn('subreddit')}
                    aria-label="Sort Playlist by Subreddit" 
                >
                    Subreddit
                    {sort.sortBy === 'subreddit' && 
                        <Icon
                            className='sortByIcon'
                            name={sort.type === 'ascending' ? 'icon-sort-up': 'icon-sort-down'}
                            width="13px"
                            height="13px"
                            color='#E7E7E7'
                            hover='#fff'
                            transitionMS='0'
                        />
                    }
                </button>
                <button 
                    className="votes"
                    onClick={() => sortFn('upvotes')}
                    aria-label="Sort Playlist by Upvotes" 
                >
                    <Icon
                        name="icon-upvote"
                        width="13px"
                        height="13px"
                        color='#E7E7E7'
                        hover='#fff'
                    />
                    {sort.sortBy === 'upvotes' && 
                        <Icon
                            className='sortByIcon'
                            name={sort.type  === 'ascending' ? 'icon-sort-up': 'icon-sort-down'}
                            width="13px"
                            height="13px"
                            color='#E7E7E7'
                            hover='#fff'
                            transitionMS='0'
                        />
                    }
                </button>
                <button 
                    className="time"
                    onClick={() => sortFn('time')}
                    aria-label="Sort Playlist by Time" 
                >
                    <Icon
                        className='sortByIcon'
                        name="icon-time"
                        width="13px"
                        height="13px"
                        color='#E7E7E7'
                        hover='#fff'
                    />
                    {sort.sortBy === 'time' && 
                        <Icon
                            className='sortByIcon'
                            name={sort.type === 'ascending' ? 'icon-sort-up': 'icon-sort-down'}
                            width="13px"
                            height="13px"
                            color='#E7E7E7'
                            hover='#fff'
                            transitionMS='0'
                        />
                    }
                </button>
            </PlaylistHeaderStyled>

            <PlaylistStyled>
                <FadeTransition inProp={!tracks.length} timeout={500}>
                    <TrackPlaceholder
                        height={windowHeight}
                    />
                </FadeTransition>

                <AutoSizer>
                    {({ width, height }) => (
                        <FadeTransition inProp={!!tracks.length} timeout={500}>
                            <TracksStyled>
                                <List
                                    ref={playlistRef}
                                    width={width}
                                    height={height}
                                    itemCount={tracks.length}
                                    itemSize={55}
                                    itemData={itemData}
                                    overscanCount={3}
                                    itemKey={index => tracks[index].spotifyId}
                                    outerElementType={CustomScrollbar}
                                    innerElementType={innerElementType}
                                >
                                    {Row}
                                </List>
                            </TracksStyled>
                        </FadeTransition>
                    )}
                </AutoSizer>
            </PlaylistStyled>
        </>
    );
};

export default memo(Playlist);