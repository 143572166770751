import styled, {keyframes} from 'styled-components';

const loadingDuration = 2000;
const loadingKeyframes = keyframes`
    0% {
        left: -50px
    }

    33% {
        left: 100%
    }

    100% {
        left: 100%
    }
`;
const loadingKeyframes2 = keyframes`
    0% {
        left: -50px
    }

    15% {
        left: -50px
    }

    66% {
        left: 100%
    }

    100% {
        left: 100%
    }
`;
const loadingKeyframes3 = keyframes`
    0% {
        left: -50px
    }

    50% {
        left: -50px
    }

    100% {
        left: 100%
    }
`;

const TrackPlaceholderStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 15px 15px 15px;

    .album {
        position: relative;
        width: 84px;
        height: 84px;
        background: #33353D;
        border-radius: 15px;
        overflow: hidden;

        ::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 50px;
            background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
            animation: ${loadingKeyframes} ${loadingDuration + 'ms'} ease-in-out infinite;
        }
    }

    .trackDetailsLeft {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 5px;
        min-width: 0;
        overflow: hidden;

        div:nth-child(1) {
            position: relative;
            margin-bottom: 10px;
            width: 106px;
            height: 12px;
            background: #33353D;
            overflow: hidden;

            ::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 50px;
                background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                animation: ${loadingKeyframes2} ${loadingDuration + 'ms'} ease-in-out infinite;
            }
        }

        div:nth-child(2) {
            position: relative;
            width: 78px;
            height: 12px;
            background: #33353D;
            overflow: hidden;

            ::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 50px;
                background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                animation: ${loadingKeyframes2} ${loadingDuration + 'ms'} ease-in-out infinite;
            }
        }

        div:nth-child(3) {
            position: relative;
            margin-top: auto;
            width: 36px;
            height: 12px;
            background: #33353D;
            overflow: hidden;

            ::before {
                content: '';
                position: absolute;
                height: 100%;
                width: 50px;
                background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                animation: ${loadingKeyframes2} ${loadingDuration + 'ms'} ease-in-out infinite;
            }
        }
    }

    .trackDetailsRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;

        .time {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            width: 55px;

            div:nth-child(1) {
                position: relative;
                width: 14px;
                height: 14px;
                background: #33353D;
                border-radius: 7px;
                overflow: hidden;

                ::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50px;
                    background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                    animation: ${loadingKeyframes3} ${loadingDuration + 'ms'} ease-in-out infinite;
                }
            }
            div:nth-child(2) {
                position: relative;
                width: 28px;
                height: 14px;
                background: #33353D;
                overflow: hidden;

                ::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50px;
                    background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                    animation: ${loadingKeyframes3} ${loadingDuration + 'ms'} ease-in-out infinite;
                }
            }
        }

        .votes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            width: 55px;

            div:nth-child(1) {
                position: relative;
                width: 14px;
                height: 14px;
                background: #33353D;
                overflow: hidden;

                ::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50px;
                    background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                    animation: ${loadingKeyframes3} ${loadingDuration + 'ms'} ease-in-out infinite;
                }
            }

            div:nth-child(2) {
                position: relative;
                width: 18px;
                height: 14px;
                background: #33353D;
                overflow: hidden;
                
                ::before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 50px;
                    background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
                    animation: ${loadingKeyframes3} ${loadingDuration + 'ms'} ease-in-out infinite;
                }
            }
        }
    }
`;

const TracksPlaceholdersStyled = styled.div`
    position: absolute;
    top: 15px;
    width: 100%;
`;

const TrackPlaceholder = () => (
    <TrackPlaceholderStyled>
        <div className="album"/>
        <div className="trackDetailsLeft">
            <div/>  
            <div/> 
            <div/>
        </div> 
        <div className="trackDetailsRight">
            <div className="time">
                <div/>
                <div/>
            </div>
            <div className="votes">
                <div/>
                <div/>
            </div>
        </div>
    </TrackPlaceholderStyled>
);

const TracksPlaceholders = ({
    height,
    state
}) => { 
    const placeholderTotal = Math.ceil(height / 114);
    return (
        <TracksPlaceholdersStyled state={state}>
            {[...Array(placeholderTotal)].fill(0).map((_, index) => <TrackPlaceholder key={`placeholder-${index}`}/>)}
        </TracksPlaceholdersStyled>
    );
};

export default TracksPlaceholders;