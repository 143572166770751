import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 20px;
    padding: 20px;
    width: calc(100vw - 40px);
    max-width: 600px;
    background: #36383F;
    border-radius: 7px;

    h1 {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 600;
        color: #E7E7E7;
    }

    p {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 20px;
        color: #B7B7B9;

        :last-child {
            margin-bottom: 0;
        }

        strong {
            font-weight: 600;
            color: #E7E7E7;
        }
    }

    a {
        color #4FBCFF;
        font-weight: 600;

        :visited {
            color #4FBCFF;
        }
    }
`;

const intro = () => {
    return (
        <Wrapper>
            <h1>Custom playlists, sourced from the top posts on Reddit</h1>
            <p>Welcome to Subreddit Playlist! Here we bring the top posts from all of your favorite <a href="https://www.reddit.com/r/Music/wiki/musicsubreddits/">music subreddits</a> into an easily digestible playlist. <strong>Hit "Create a playlist", select your favorite subreddits, a timeframe, and get listening!</strong></p>
            <p>If you have any feedback, I'd love to hear it at <a href="https://www.reddit.com/r/SubredditPlaylist">/r/subredditplaylist</a>.</p>
        </Wrapper>
    );
};

export default intro;