import styled, {keyframes} from 'styled-components';

const loadingDuration = 2000;
const loadingKeyframes = keyframes`
    0% {
        left: -50px
    }

    33% {
        left: 100%
    }

    100% {
        left: 100%
    }
`;
const loadingKeyframes2 = keyframes`
    0% {
        left: -50px
    }

    25% {
        left: -50px
    }

    66% {
        left: 100%
    }

    100% {
        left: 100%
    }
`;

const TrackPlaceholderStyled = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;

    .album {
        position: relative;
        width: 45px;
        height: 45px;
        background: #33353D;
        border-radius: 5px;
        overflow: hidden;

        ::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 50px;
            background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
            animation: ${loadingKeyframes} ${loadingDuration + 'ms'} ease-in-out infinite;
        }
    }

    .trackDetails {
        position: relative;
        margin-left: 10px;
        flex: 1;
        height: 45px;
        border-radius: 5px;
        background: #33353D;
        overflow: hidden;

        ::before {
            content: '';
            position: absolute;
            height: 100%;
            width: 50px;
            background: linear-gradient(90deg, rgba(225,225,225,0) 0%, rgba(225,225,225,.05) 50%, rgba(225,225,225,0) 100%);
            animation: ${loadingKeyframes2} ${loadingDuration + 'ms'} ease-in-out infinite;
        }
    }
`;

const TracksPlaceholdersStyled = styled.div`
    position: absolute;
    top: 15px;
    width: 100%;
`;

const TrackPlaceholder = () => (
    <TrackPlaceholderStyled>
        <div className="album"/>
        <div className="trackDetails"/>
    </TrackPlaceholderStyled>
);

const TracksPlaceholders = ({
    height,
    state
}) => { 
    const placeholderTotal = Math.ceil(height / 45);
    return (
        <TracksPlaceholdersStyled state={state}>
            {[...Array(placeholderTotal)].fill(0).map((_, index) => <TrackPlaceholder key={`placeholder-${index}`}/>)}
        </TracksPlaceholdersStyled>
    );
};

export default TracksPlaceholders;