import styled from 'styled-components';
import searchIcon from 'img/icon-search.svg';

const SearchComponent = styled.div `
    display: flex;
    border-radius: 5px 5px 0 0;
    background: #36383F;

    .searchInput {
        margin: 15px;
        padding-left: 35px;
        width: 100%;
        height: 35px;
        border: 0;
        border-radius: 15px;
        font-family: 'Open Sans',sans-serif;
        font-size: 1em;
        color: #E7E7E7;
        background: #6E707B no-repeat 10px center url(${searchIcon});

        &::placeholder {
            color: #979797;
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #3c96ce;
        }
    }
`;

const Search = ({
    value,
    onChangeFn
}) => {
    return ( 
        <SearchComponent>
            <input 
                className="searchInput"
                type="text"
                placeholder="Search for a Subreddit"
                value={ value }
                onChange={ e => onChangeFn(e.target.value) }
            /> 
        </SearchComponent>
    );
};

export default Search;