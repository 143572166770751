import styled from 'styled-components';
import Icon from 'components/Icon';

const AddToPlaylistStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0;
    border-radius: 5px;
    background: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    .buttonText {
        margin-right: 10px;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #E7E7E7;
    }
`;

const AddToPlaylist = ({
    buttonText,
    active
}) => {
    return (
        <AddToPlaylistStyled
            active={active}
            aria-label="Sort Playlist" 
        >
            <span className="buttonText">{buttonText}</span>
            <Icon name="icon-sort" width="21px" color="#E7E7E7"/> 
        </AddToPlaylistStyled>
    );
};

export default AddToPlaylist;