import React, { useState, useEffect } from 'react';
import { navigate, globalHistory } from "@reach/router";
import ReactGA from 'react-ga';

import GlobalStyles from './util/globalStyles';

import { AppContextProvider } from './context/AppContext';
import { ViewPortProvider } from './hooks/useViewport';

import subredditList from 'json/subreddits.json';
import HomeContainer from 'containers/HomeContainer';
import PlayListContainer from 'containers/PlayListContainer';
import FadeRouter from 'components/FadeRouter';

const App = () => {
  const [playlistSeed, setPlaylistSeed] = useState({
    subreddits: [],
    timeFrame: 'Week'
  });

  useEffect(() => {
    ReactGA.initialize('UA-217813358-1');
    globalHistory.listen(({location}) => {
      ReactGA.pageview(window.location.pathname);
    });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subreddits = urlParams.get('subreddits') || '';
    const timeFrame = urlParams.get('timeframe') || '';
  
    // On first load, figure out  which page to load based on the url
    if (!playlistSeed.subreddits.length && subreddits && timeFrame) {
      const subredditArray = subreddits.split(',');

      setPlaylistSeed(() => ({
        subreddits: subredditList.filter(supportedSubreddit => {
          return subredditArray.includes(supportedSubreddit.url);
        }),
        timeFrame: timeFrame
      }))
    }

    if (playlistSeed.subreddits.length && playlistSeed.timeFrame.length) {
      navigate(`/playlist/?timeframe=${playlistSeed.timeFrame}&subreddits=${
          playlistSeed.subreddits.map(subreddit => subreddit.url).join(',')
      }`, {replace: true});
    } else {
        navigate('/');
    }

  },[playlistSeed]);


  return (
    <>
      <GlobalStyles/>
      <AppContextProvider value={{
        playlistSeed,
        setPlaylistSeed,
        subredditList
      }}>
        <ViewPortProvider>
          <FadeRouter>
            <HomeContainer 
              path="/"
            />
            <PlayListContainer
              path="/playlist"
            />
          </FadeRouter>
        </ViewPortProvider>
      </AppContextProvider>
    </>
  );
};

export default App;