import { useCallback, forwardRef } from 'react';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars-2';

const RenderThumbVerticalStyled = styled.div`
    background: rgb(231 231 231 / 40%);
    border-radius: 5px;
    transition: 100ms background ease-in-out;

    :hover {
        background: rgb(231 231 231 / 1);
    }
`;

const RenderThumbHorizontalStyled = styled.div`
    height: 6px;
    background: rgb(231 231 231 / 40%);
    border-radius: 5px;
    transition: 100ms background ease-in-out;

    :hover {
        background: rgb(231 231 231 / 1);
    }
`;

const RenderViewStyled = styled.div`
    ${({style}) => (
        {
            ...style,
            flex: 1
        }
    )};

    :hover {
        ~ div {
            opacity: 1 !important;
        }
    }
`;

const renderThumbVertical = () => {
    return (
        <RenderThumbVerticalStyled/>
    );
};

const renderThumbHorizontal = () => {
    return (
        <RenderThumbHorizontalStyled/>
    );
};

const renderView = props => {
    return <RenderViewStyled {...props}/>
};

const CustomScrollbars = ({
    children,
    style,
    onScroll,
    autoHeight,
    autoHeightMax,
    forwardedRef
}) => {
    const refSetter = useCallback(scrollbarsRef => {
        if (scrollbarsRef) {
            forwardedRef(scrollbarsRef.view);
        } else {
            forwardedRef(null);
        }
    }, [forwardedRef]);

    return (
        <Scrollbars
            // forwardedRef will be a function if coming from react-window
            ref={typeof forwardedRef == 'function' ? refSetter : forwardedRef}
            onScroll={onScroll}
            renderThumbHorizontal={renderThumbHorizontal}
            renderThumbVertical={renderThumbVertical}
            renderView={renderView}
            autoHide={true}
            autoHeight={autoHeight}
            autoHeightMax={autoHeightMax}
            style={{ 
                ...style, 
                overflow: 'hidden',
            }}
        >
            {children}
        </Scrollbars>
    );
};

const CustomScrollbarsVirtualList = forwardRef((props, ref) => (
    <CustomScrollbars {...props} forwardedRef={ref} />
));

export default CustomScrollbarsVirtualList;