import styled from 'styled-components';
import icons from 'img/icons.svg';

const IconStyled = styled.svg`
    width: ${props => props.width};
    fill: ${props => props.color};
    transition: ${props => props.transition} fill ease-in;

    :hover {
        fill: ${props => props.hover};
    }
`;

const Icon = ({
    name,
    color,
    hover,
    width,
    transitionMS,
    ...rest
}) => {
    return (
        <IconStyled 
            {...rest}
            preserveAspectRatio="xMidYMid" 
            viewBox="0 0 100 100"
            width={width}
            color={color}
            hover={hover}
            transition={transitionMS || '200ms'}
        >
            <use href={`${icons}#${name}`}/>
        </IconStyled>
    )
};

export default Icon;