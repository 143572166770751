import { memo, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const backgroundFadeIn = keyframes `
    from {
        opacity: 0;
    }

    to {
        opacity: 50%;
    }
`;

const AlbumContainerStyled = styled.div `
    position: relative;
    aspect-ratio: 1;
`;

const AlbumStyled = styled.div `
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({background}) => Object.keys(background).length ? `url(${background.highRes}), url(${background.lowRes}), linear-gradient(to bottom, ${background.gradient.join(',')})` : ''};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: ${props => props.visible ? '50%' : 0};
    transition: .2s opacity ease-in;
    animation: ${backgroundFadeIn} .5s ease-in;
`;

const PlayerAlbum = memo(
    ({
        albumArt,
        size
    }) => {
        const [newState, setNewState] = useState(albumArt)
        const [oldState, setOldState] = useState('');
        const [topVisible, setTopvisibile] = useState(true);
        const [bottomVisible, setBottomVisible] = useState(false);

        useEffect(() => {
            if (albumArt !== newState) {
                setOldState(() => newState);
                setNewState(() => albumArt)

                setTopvisibile(old => !old);
                setBottomVisible(old => !old)
            }
        }, [albumArt, newState]);

        return (
            <AlbumContainerStyled size={size}>
                <AlbumStyled 
                    background={topVisible ? newState : oldState}
                    visible={topVisible}
                />
                <AlbumStyled 
                    background={bottomVisible ? newState : oldState}
                    visible={bottomVisible}
                />
            </AlbumContainerStyled>
        );
    }
);

export default PlayerAlbum;