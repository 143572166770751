import styled from 'styled-components';
import modalArrowIcon from 'img/modal-arrow.svg';

const TimelinePickerComponent = styled.ul`
    position: relative;
    display: grid;
    grid-template-columns: 25fr 25fr 25fr 25fr;
    gap: 3vw;
    margin-top: 30px;
    padding: 15px;
    border-radius: 5px;
    background: #36383F;

    ::before {
        content: '';
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-10px);
        width: 20px;
        height: 13px;
        background: url(${modalArrowIcon});
    }

    .timeFrame {
        margin-right: 15px;
        width: 100%;

        &:last-child {
            margin-right: 0;
        }
    }

    .toggleButton {
        width: 100%;
        padding: min(13px, calc(5px + 2vw)) 0;
        font-size: .875rem;
        border: 0;
        border-radius: 8px;
        font-weight: 600;
        color: #9598A6;
        background: #2D2E35;
        cursor: pointer;

        &.is-active {
            background: #3C96CE;
            color: #fff;
        }


        :focus {
            outline: none;
        }
        :hover:not(.is-active) {
            color: #fff;
            background: #575A65;
        }
    }
`;

const TimelinePicker = ({
    active,
    onClickFn
}) => {
    return (
        <TimelinePickerComponent>
            {['Today', 'Week', 'Month', 'Year'].map(timeFrame => {
                return (
                    <li
                        className="timeFrame"
                        key={`timeFrame-${timeFrame}`}
                    >
                        <button
                            className={`toggleButton ${active(timeFrame) ? 'is-active' : ''}`}
                            onClick={() => onClickFn(null, timeFrame)}
                        >
                            {timeFrame}
                        </button>
                    </li>
                )
            })}
        </TimelinePickerComponent>
    )
};

export default TimelinePicker;