import { useContext, useState, useEffect, useCallback, memo } from 'react';
import { AppContext } from 'context/AppContext';

import styled from 'styled-components';

import Search from 'components/playlistBuilder/Search';
import SubredditList from 'components/playlistBuilder/SubredditList';
import TimelinePicker from 'components/playlistBuilder/TimelinePicker';
import FinishedButton  from 'components/buttons/FinishedButton';

import fuzzysort from 'fuzzysort';


const PlaylistBuilder = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
`;

const PlaylistBuilderContainer = ({
    action
}) => {
    const {
        playlistSeed,
        setPlaylistSeed,
        subredditList
    } = useContext(AppContext);

    const [ pendingPlaylistSeed, setPendingPlaylistSeed ] = useState({ ...playlistSeed });
    const [ searchFilter, setSearchFilter ] = useState('');
    const [ filteredSubreddits, setFilteredSubreddits ] = useState(subredditList);

    useEffect(() => {
        /*
            There's probably a better way to do this.
            If someone loads the page with a /playlist in the url,
            this container will load and set pendingPlaylistSeed
            before the app.js useEffect can set the playlistSeed.
            This results in pendingPlaylistSeed having stale data.
        */
        setPendingPlaylistSeed(() => ({...playlistSeed}));

        if (searchFilter === '') {
            setFilteredSubreddits(subredditList);
        } else {
            var filter = fuzzysort
                .go(searchFilter, subredditList.map(subreddit => subreddit.name))
                .map(filtered => filtered.target);
            
            setFilteredSubreddits(subredditList.filter(subreddit => filter.includes(subreddit.name)));
        }

    }, [searchFilter, playlistSeed, subredditList])

    const updatePendingPlaylistSeed = useCallback(
        (subreddit, timeFrame) => {
            const pendingSubreddits = pendingPlaylistSeed.subreddits.map(subreddit => subreddit.name);

            if (subreddit) {
                if (pendingSubreddits.includes(subreddit.name)) {
                    setPendingPlaylistSeed(prev => {
                        return ({
                            subreddits: prev.subreddits.filter(pendingSubreddit => pendingSubreddit.name !== subreddit.name),
                            timeFrame: prev.timeFrame
                        });
                    });
                } 
                else {
                    setPendingPlaylistSeed(prev => {
                        return({
                            subreddits: [...prev.subreddits, subreddit],
                            timeFrame: prev.timeFrame
                        });
                    });
                }
            }

            if (timeFrame) {
                setPendingPlaylistSeed(prev => {
                    return({
                        subreddits: prev.subreddits,
                        timeFrame: timeFrame
                    });
                });
            }
        }, [pendingPlaylistSeed]);
    
    const finished = useCallback(
        () => {
            action();
            setTimeout(() => {
                setPlaylistSeed({...pendingPlaylistSeed});
            }, 200)
        }, [pendingPlaylistSeed, action, setPlaylistSeed]);
       
    const subredditsByGenre = useCallback(
        () => filteredSubreddits.reduce((acc, subreddit) => {
            subreddit.genres.forEach(genre => {
                if (Object.keys(acc).includes(genre)) {
                    acc[genre].push(subreddit);
                } else {
                    acc[genre] = [subreddit];
                }
            })
            return acc;
        }, {}), [filteredSubreddits])();

    const subredditActive = useCallback(
        subreddit => {
            return pendingPlaylistSeed.subreddits.map(subreddit => subreddit.url.toLowerCase()).includes(subreddit.url.toLowerCase()) ? true : false;
        }, [pendingPlaylistSeed]);

    const timeFrameActive = useCallback(
        timeFrame => {
            return pendingPlaylistSeed.timeFrame === timeFrame ? true : false;
        }, [pendingPlaylistSeed]);

    return (
        <PlaylistBuilder>
            <Search
                value={searchFilter}
                onChangeFn={setSearchFilter}
            />

            <SubredditList
                subredditsByGenre={subredditsByGenre}
                active={subredditActive}
                onClickFn={updatePendingPlaylistSeed}
            />

            <TimelinePicker
                active={timeFrameActive}
                onClickFn={updatePendingPlaylistSeed}
            />

            <FinishedButton
                text="Create..."
                active={(pendingPlaylistSeed.subreddits.length && pendingPlaylistSeed.timeFrame) ? true : false}
                onClickFn={() => finished()}
            />
        </PlaylistBuilder>
    );
};

export default memo(PlaylistBuilderContainer);