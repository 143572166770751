import styled, { keyframes } from 'styled-components';

const quiet = keyframes`
    25% {
        transform: scaleY(.6);
    }
    50% {
        transform: scaleY(.4);
    }
    75% {
        transform: scaleY(.8);
    }
`;

const normal = keyframes`
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(.4);
    }
    75% {
        transform: scaleY(.6);
    }
`;

const fadeIn = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 100%;
    }
`;

const PlayingContainerStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(2,0,36,.9) 0%, rgba(255,255,255,0) 100%);
    z-index: 1;
    animation-name: ${fadeIn};
    animation-duration: 200ms;

    // Fixes ios bug where overflow doesn't work for the duration of the animation
    border-radius: ${({size}) => size === 'big' ? '15px' : '5px'};
`;

const PlayingStyled = styled.div`
    display: flex;
    justify-content: space-between;
    height: ${({ size }) => size === 'big' ? '64px' : '32px'};
    --boxSize: ${({ size }) => size === 'big' ? '8px' : '4px'};
    --gutter: ${({ size }) => size === 'big' ? '4px' : '2px'};
    width: calc((var(--boxSize) + var(--gutter)) * 3);

    .box{
        transform: scaleY(.4);
        height: 100%;
        width: var(--boxSize);
        background: #3C96CE;
        animation-duration: 1.2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
    }

    .box1{
        animation-name: ${quiet};
        animation-play-state: ${props => props.playing ? 'running' : 'paused'};
    }

    .box2{
        animation-name: ${normal};
        animation-play-state: ${props => props.playing ? 'running' : 'paused'};
    }

    .box3{
        animation-name: ${quiet};
        animation-play-state: ${props => props.playing ? 'running' : 'paused'};
    }
`;

const PlayingAnimation = ({
    playing,
    size
}) => {
    return (
        <PlayingContainerStyled 
            size={size}
        >
            <PlayingStyled 
                playing={playing}
                size={size}>
                <div className="box box1"/>
                <div className="box box2"/> 
                <div className="box box3"/> 
            </PlayingStyled> 
        </PlayingContainerStyled>
    );
};

export default PlayingAnimation;