import { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Range, getTrackBackground } from 'react-range';
import Icon from 'components/Icon';

const ControlsStyled = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    width: calc(100% - 30px);
    max-width: 500px;
    border: 1px solid #474747;
    border-radius: 15px;
    background: rgba(54, 56, 63, .80);
    backdrop-filter: blur(3px);
    z-index: 3;

    .bottom {
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-shrink: 0;

        .barControls {
            flex: 1;
            margin: 0 10px;
        }

        .timeText  {
            margin-top: 5px;
            font-size: 0.875em;
            color: ${({ controlsActive }) => controlsActive ? '#E7E7E7' : '#565656'};
            cursor: default;
            transition: 200ms color ease-in;
        }

        .volumeControls {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .volumeToggleBtn {
                padding: 0;
                border: none;
                background: none;
                -webkit-tap-highlight-color: transparent;
                cursor: pointer;
            }
        }
    }

    .playerControls {
        display: flex;
        justify-content: center;

        .playerControls-outer {
            display: flex;
            width: 200px;
            justify-content: space-between;
        }

        .playerControls-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 125px;
        }

        .backBtn, .forwardBtn {
            padding: 0;
            border: none;
            background: none;
            -webkit-tap-highlight-color: transparent;
            cursor: ${({ activeTrack }) => activeTrack ? 'pointer' : 'default'};
        }

        .togglePlayBtn, .shuffleBtn, .repeatBtn, .volumeToggleBtn {
            padding: 0;
            border: none;
            background: none;
            -webkit-tap-highlight-color: transparent;
            cursor: pointer;
        }
    }    
`;


/*
Over 200 classes were generated for component styled.div with the id of "sc-efHYUO".
Consider using the attrs method, together with a style object for frequently changed styles.
Example:
  const Component = styled.div.attrs(props => ({
    style: {
      background: props.background,
    },
  }))`width: 100%;`
*/

const ProgressTrack = styled.div`
    width: 100%;
    height: 25px;
    border-radius: 2px;

    ::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 6px;
        background: ${props => props.progress};
        border-radius: 2px;
    }

    :hover {
        .thumb {
            opacity: ${({ active }) => active ? 1 : 0}
        }
    }
    
`;

const VolumeTrack = styled.div`
    margin-left: 10px;
    width: 50%;
    height: 25px;
    border-radius: 2px;

    ::before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 6px;
        background: ${props => props.progress};
        border-radius: 2px;
    }

    :hover {
        .thumb {
            opacity: ${({ active }) => active ? 1 : 0};
            background: ${({ thumbColor }) => thumbColor};
        }

        ::before {
            background: ${({ progressHover }) => progressHover};;
        }
    }
`;

const Thumb = styled.div`
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background: ${({ color }) => color};
    opacity: ${props => props.isDragged ? 1 : 0};
`;

const Controls = ({
    timeInto,
    totalSeconds,
    playing,
    volume,
    activeTrack,
    controlsActive,
    sort,
    setSort,
    repeat,
    setRepeat,


    seekToFn,
    togglePlayFn,
    forwardFn,
    backwardFn,
    setVolume

}) => {
    const [volumeVisible, setVolumeVisible] = useState(false)
    const [dragging, setDragging] = useState(false);
    const [volumeDragging, setVolumeDragging] = useState(false);
    const [volumeLastState, setVolumeLastState] = useState(volume);
    const [sortLastState, setSortLastState] = useState(sort);

    const getTrackDisplayTimes = useCallback(
        () => {
            var into = dragging ? dragging : timeInto;

            return [
                new Date(Math.ceil((into) * 1000)).toISOString().substr(14, 5),
                new Date(Math.ceil((totalSeconds) * 1000)).toISOString().substr(14, 5)
            ];
        }, [timeInto, dragging, totalSeconds]);

    const toggleSort = useCallback(
        () => {
            if (sort.sortBy === 'shuffle') {
                setSort(sortLastState);
            } else {
                setSortLastState({ ...sort });
                setSort({
                    sortBy: 'shuffle',
                    type: null
                });
            }
        }, [sort, sortLastState, setSort, setSortLastState]);

    const toggleRepeat = useCallback(
        () => {
            if (repeat) {
                setRepeat(false);
            } else {
                setRepeat(true);
            }
        }, [repeat, setRepeat]);

    useEffect(() => {

        // Some environments, you can't set the volume at an app level (ios)
        // https://stackoverflow.com/questions/59417075/javascript-detect-if-volume-can-be-set
        function testMediaElementVolumeSetterAsync() {
            const timeoutPromise = new Promise(resolve => setTimeout(resolve, 1e3, false))
            const promise = new Promise((resolve) => {
                let video = document.createElement('video')
                const handler = () => {
                    video.removeEventListener('volumechange', handler)
                    video = null

                    resolve(true)
                }

                video.addEventListener('volumechange', handler)

                video.volume = 0.5
            })

            return Promise.race([promise, timeoutPromise])
        }

        testMediaElementVolumeSetterAsync().then(data => data ? setVolumeVisible(true) : setVolumeVisible(false));
    }, []);

    return (
        <ControlsStyled
            controlsActive={controlsActive}
            activeTrack={activeTrack}
        >
            <div className="playerControls">
                <div className="playerControls-outer">
                    <button
                        onClick={() => toggleSort()}
                        className="shuffleBtn"
                        title="Shuffle"
                        aria-label="Shuffle Playlist" 
                    >

                        <Icon
                            name="icon-controls-shuffle"
                            width="15px"
                            color={
                                (sort.sortBy === 'shuffle') ? '#3C96CE' : "#E7E7E7"
                            }
                            hover={(sort.sortBy === 'shuffle') ? '#3C96CE' : "#FFF"}
                        />
                    </button>
                    <div className="playerControls-inner">
                        <button
                            onClick={() => activeTrack && backwardFn()}
                            className="backBtn"
                            aria-label="Back Playlist Control" 
                        >
                            <Icon
                                name="icon-controls-back"
                                width="25px"
                                color={activeTrack ? "#E7E7E7" : "#666974"}
                                hover={activeTrack ? "#fff" : "#666974"}

                            />
                        </button>

                        <button
                            onClick={() => togglePlayFn()}
                            className="togglePlayBtn"
                            aria-label="Toggle Play Playlist Control" 
                        >
                            <Icon
                                name={playing ? "icon-controls-pause" : "icon-controls-play"}
                                width="40px"
                                color={"#E7E7E7"}
                                hover={"#fff"}
                            />
                        </button>

                        <button
                            onClick={() => activeTrack && forwardFn()}
                            className="forwardBtn"
                            aria-label="Forward Playlist Control" 
                        >
                            <Icon
                                name="icon-controls-forward"
                                width="25px"
                                color={activeTrack ? "#E7E7E7" : "#666974"}
                                hover={activeTrack ? "#fff" : "#666974"}

                            />
                        </button>
                    </div>
                    <button
                        onClick={() => toggleRepeat()}
                        className="repeatBtn"
                        title="Repeat"
                        aria-label="Repeat Playlist Control" 
                    >
                        <Icon
                            name="icon-controls-repeat"
                            width="15px"
                            color={repeat ? "#3C96CE" : "#E7E7E7"}
                            hover={repeat ? "#3C96CE" : "#fff"}
                        />
                    </button>
                </div>
            </div>

            <div className="bottom">
                <div className="timeText timeText--left">{getTrackDisplayTimes()[0]}</div>
                <div className="barControls">
                    <div className="timeControls">
                        <Range
                            step={1}
                            min={0}
                            max={totalSeconds || 1}
                            values={dragging ? [dragging] : [timeInto]}
                            disabled={!activeTrack || !controlsActive}
                            renderThumb={({ props, isDragged }) => (
                                <Thumb
                                    {...props}
                                    isDragged={isDragged}
                                    className="thumb"
                                    color='#3C96CE'
                                    aria-label="Timeline Scrubber" 
                                />
                            )}
                            renderTrack={({ props, children }) => (
                                <ProgressTrack
                                    {...props}
                                    progress={getTrackBackground({
                                        values: dragging ? [dragging] : [timeInto],
                                        min: 0,
                                        max: totalSeconds || 1,
                                        colors: ['#3C96CE', '#666974']
                                    })}
                                    thumbColor='#3C96CE'
                                    active={activeTrack && controlsActive}
                                >
                                    {children}
                                </ProgressTrack>
                            )}
                            onChange={value => {
                                setDragging(() => value);
                                seekToFn(value[0]);
                            }}
                            onFinalChange={() => {
                                setDragging(() => false);
                            }}
                        />
                    </div>
                    {volumeVisible &&
                        <div className="volumeControls">
                            <button
                                onClick={() => {
                                    if (volume > 0) {
                                        setVolumeLastState(volume);
                                        setVolume(0);
                                    } else {
                                        setVolume(volumeLastState);
                                    }
                                }}
                                className="volumeToggleBtn"
                                aria-label="Toggle Mute Playlist Control" 
                            >
                                <Icon
                                    name={
                                        (volume === 0) && "icon-controls-volume-1" ||
                                        (volume <= 25) && "icon-controls-volume-2" ||
                                        (volume <= 75) && "icon-controls-volume-3" ||
                                        (volume <= 100) && "icon-controls-volume-4"
                                    }
                                    width="18px"
                                    color={"#E7E7E7"}
                                    hover={"#fff"}
                                />
                            </button>

                            <Range
                                step={1}
                                min={0}
                                max={100}
                                values={volumeDragging ? [volumeDragging] : [volume]}
                                disabled={false}
                                renderThumb={({ props, isDragged }) => (
                                    <Thumb
                                        {...props}
                                        isDragged={isDragged}
                                        className="thumb"
                                        color='#E7E7E7'
                                        aria-label="Change Volume" 
                                    />
                                )}
                                renderTrack={({ props, children }) => (
                                    <VolumeTrack
                                        {...props}
                                        progress={getTrackBackground({
                                            values: volumeDragging ? [volumeDragging] : [volume],
                                            min: 0,
                                            max: 100,
                                            colors: ['#E7E7E7', '#666974']
                                        })}
                                        progressHover={getTrackBackground({
                                            values: volumeDragging ? [volumeDragging] : [volume],
                                            min: 0,
                                            max: 100,
                                            colors: ['#fff', '#666974']
                                        })}
                                        thumbColor='#fff'
                                        active={true}
                                    >
                                        {children}
                                    </VolumeTrack>

                                )}
                                onChange={value => {
                                    setVolumeDragging(() => value);
                                    setVolume(value[0]);
                                }}
                                onFinalChange={() => {
                                    setVolumeDragging(() => false);
                                }}
                            />
                        </div>
                    }

                </div>
                <div className="timeText timeText--right">{getTrackDisplayTimes()[1]}</div>
            </div>
        </ControlsStyled>
    );
};

export default Controls;