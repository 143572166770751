import styled from 'styled-components';
import logo from 'img/logo.svg';
import logoWink from 'img/logo-wink.svg';

const LogoStyled = styled.img `
    min-width: 250px;
    max-width: 308px;
    min-height: 52px;
    z-index: 9;
`;

const Logo = ({
    wink
}) => {
    if (wink) {
        return <LogoStyled 
            src = {logoWink}
            alt="Subreddit playlist Logo"
        />
    } else {
        return <LogoStyled 
            src={logo}
            alt="Subreddit playlist Logo"
        />
    }
};

export default Logo;