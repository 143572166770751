import styled from 'styled-components';
import Icon from 'components/Icon';

const FinishedButtonComponent = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin-top: 15px;
    height: 60px;
    border: 0;
    border-radius: 10px;
    background: #666974;
    cursor: pointer;
    transition: 200ms background ease-in;

    .buttonText {
        margin-left: 10px;
        font-size: 1.125em;
        font-weight: 600;
        color: ${props => props.active ? '#fff' : '#909090'}
    }

    :hover {
        background: ${({active}) => active ? '#7a7e8b' : '#666974'};
    }
`;

const FinishedButton = ({
    active,
    text,
    onClickFn
}) => {
    return (
        <FinishedButtonComponent
            active={active}
            onClick={() => onClickFn()}
            aria-label="Create a Playlist" 
        >
            <Icon
                name={active ? "icon-check-active" : "icon-check-inactive"}
                color={active ? "#fff" : "#909090"}
                width="20px"
            />
            <span className="buttonText">
                {text}
            </span>
        </FinishedButtonComponent>
    );
};

export default FinishedButton;
