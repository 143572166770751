import reset from 'styled-reset';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle `
    ${reset}
    @import url(//fonts.googleapis.com/css?family=Open+Sans:400,600,800);

    * {
        box-sizing: border-box;
    }

    body {
        font-family: 'Open Sans', sans-serif;
        font-size: min(16px, calc(1vw + 12px));
        background: #222328;
        overflow: hidden;
    }

    button, input { 
        font-size: inherit;
        font-family: inherit;
    }
`;