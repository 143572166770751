import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PlayingAnimation from 'components/playlist/PlayingAnimation';
import Icon from './Icon';

const AlbumStyled = styled.div`
    position: relative;
    flex-shrink: 0;
    width: ${({size}) => size === 'big' ? '85px' : '45px'};
    height: ${({size}) => size === 'big' ? '85px' : '45px'};
    background: ${({background}) => {
        return `linear-gradient(to bottom, ${background.join(',')})`;
    }};
    border-radius: ${({size}) => size === 'big' ? '15px' : '5px'};
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
    }
`;

const Album = ({
    details,
    transitionTime, 
    playing,
    active,
    size
}) => {
    const [loaded, setLoaded] = useState(false);
    const { album, url, background } = details;

    useEffect(() => {
        const img = new Image();

        img.addEventListener('load', () => setLoaded(url));
        img.src = url;

        return () => img.removeEventListener('load', () => setLoaded(url));
    }, [url]);

    return (
        <AlbumStyled
            background={background}
            transitionTime={transitionTime}
            className="albumArt"
            size={size}
        >
            {active && <PlayingAnimation playing={playing} size={size}/>}
            {!!loaded && <img src={loaded} alt={`${album} album art`}/>}
        </AlbumStyled>
    );
};

export default Album;