import React, {useState, useEffect, useCallback, createContext, useContext} from 'react';

const ViewPortContext = createContext();

const ViewPortProvider = ({children}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  

  const handleResize = useCallback(
    () => {
      var debounce = false;
      if (debounce) window.cancelAnimationFrame(debounce);

      debounce = window.requestAnimationFrame(() => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      });
      
    }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <ViewPortContext.Provider 
      value={{
        windowWidth, 
        windowHeight
      }}
    >
      {children}
    </ViewPortContext.Provider>
  )
};

const useViewport = () => {
  const {windowWidth, windowHeight} = useContext(ViewPortContext);

  return ({
    windowWidth,
    windowHeight
  });
};

export {useViewport, ViewPortProvider};