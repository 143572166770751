import { Transition } from "react-transition-group";
import styled  from 'styled-components';

const FadeTransitionStyled = styled.div`
    transition: ${({timeout}) => `${timeout}ms`} opacity ease-in;
    opacity: ${({state}) => {
        switch (state) {
            case 'entering':
                return 1;
            case 'entered':
                return 1;
            case 'exiting':
                return 0;
            case 'exited':
                return 0;
            default:
                return 0;
        }
    }};
`;

const FadeTransition = ({children, inProp, timeout}) => (
    <Transition in={inProp} timeout={timeout}>
        {state => (
            <FadeTransitionStyled state={state} timeout={timeout}>
                {children}
            </FadeTransitionStyled>
        )}
    </Transition>
);

export default FadeTransition;