import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const MediaPlayerStyled = styled.div`
    position: absolute;
    aspect-ratio: 16/9;
    left: 50%;
    top: 50%;
    transform: translate(-50%, calc(-50% - 15px));
    height: ${({ playerHeight }) => `${playerHeight}px`};
    background: #000;
    opacity: 1;
    z-index: 1;
    transition: 200ms opacity ease-in;

    &[active="false"] {
        opacity: 0;
    }
`;

const MediaPlayer = ({
    volume,
    activeTrack,
    playerRef,
    playing,
    trackEndFn,
    trackEndErrorFn,
    onProgressFn,
    setPlaying,
    setControlsActive,
    playerHeight
}) => {
    /*
        This is used so that a track that has multiple urls can try all of them if one fails.
        A side effect of doing this is that the time in the playlist might not match the video time because
        they're different sources. I could update the root tracks, but that would cause a re-render of the page.

        Once I make an automation to mark a post as unplayable, this should be an extreme edge case, so I think this is fine.
    */
    const [activeTrackCopy, setActiveTrackCopy] = useState(activeTrack);

    useEffect(() => {
        setActiveTrackCopy(activeTrack);
    }, [activeTrack]);

    return (
        <MediaPlayerStyled
            playerHeight={playerHeight}
        >
            <ReactPlayer
                url={activeTrackCopy.trackURL[0].url}
                width="100%"
                height="100%"
                ref={playerRef}
                playing={playing}
                playsinline={true}
                controls={false}
                volume={volume / 100}
                progressInterval={1000}

                onEnded={() => {
                    trackEndFn();
                }}
                onProgress={({ played, playedSeconds }) => {
                    if (!played || !playedSeconds || !playing) return;
                    const totalSeconds = 1 / played * playedSeconds;

                    onProgressFn({
                        secondsInto: Math.round(playedSeconds),
                        totalSeconds: Math.round(totalSeconds),
                        changedTime: performance.now()
                    });
                }}
                onReady={self => {
                    if (self.getDuration() !== 0) {
                        setPlaying(() => true);
                    } else {
                        if (activeTrackCopy.trackURL.length > 1) {
                            setActiveTrackCopy(prev => ({
                                ...prev,
                                trackURL: prev.trackURL.slice(1)
                            }));
                        } else {
                            trackEndErrorFn();
                        }
                    }
                }}
                onPause={() => {
                    setPlaying(() => false);
                }}
                onPlay={() => {
                    setPlaying(() => true);
                    setControlsActive(() => true);
                }}
                onError={() => {
                    trackEndErrorFn();
                }}
                onStart={() => {
                    
                }}
            />
        </MediaPlayerStyled>
    )
};

export default MediaPlayer;