import { memo, useEffect } from 'react';
import styled from 'styled-components';


const ActionBarComponent = styled.div`
    width: 100%;
    position: relative;
    top: -30px;
    margin-bottom: -30px;
    padding: 0 20px 10px;

    .top, .bottom {
        display: flex;
        flex-direction: row;
    }

    .bottom {
        justify-content: flex-end;
    }
`;

const ActionBar = ({
    topContent,
    bottomContent,
}) => {
    return (
        <ActionBarComponent>
            <div className="top">
                {topContent}               
            </div>

            <div className="bottom">
                {bottomContent}
            </div>
        </ActionBarComponent>
    );
};

export default memo(ActionBar);