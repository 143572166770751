import styled from "styled-components";
import CustomScrollbars from 'components/CustomScrollbars';

const TagsComponent = styled.div `
    overflow: hidden;
    flex: 1;
    position: relative;

    .tags-list-container {
        flex: 1;
        position: relative;
        mask-image: linear-gradient(to right, rgba(0, 0, 0, 1.0) 80%, transparent);
    }

    ul {
        display: flex;
        padding-bottom: 15px;
        
    }

    li {
        flex-shrink: 0;
        margin-right: 10px;
        padding: 10px;
        border-radius: 5px;
        font-size: 0.875em;
        color: #E7E7E7;
        background: #36383F;
        cursor: default;

        :last-child {
            .tags-button {
                margin-right: 25px;
            }
        } 
    }

    .simplebar-scrollbar::before {
        background: rgb(231 231 231 / 80%);
    }
`;

const Tags = ({
    subreddits,
    tagsKey
}) => {
    const key = tagsKey || 'tags';
    return ( 
        <TagsComponent>
            <div className="tags-list-container">
                <CustomScrollbars
                    autoHeight={true}
                >
                    <ul> 
                        {subreddits.map((name, index) => ( 
                            <li key={`${key}-${index}`}>
                                { name } 
                            </li>
                        ))} 
                    </ul>
                </CustomScrollbars>
            </div>
        </TagsComponent>
    )
};

export default Tags;