import { Router, Location } from "@reach/router";
import { TransitionGroup, Transition } from "react-transition-group";
import styled from 'styled-components';

const fadeDuration = 500;
const FadeTransitionStyled = styled.div`
  transition: ${fadeDuration + 'ms'} opacity ease-in;
  opacity: ${({state}) => {
    switch (state) {
      case 'entering':
        return 1
      case 'entered':
        return 1
      case 'exiting':
        return 0
      case 'exited':
        return 0
      default:
        return 0
    }
  }};
`;

const FadeTransition = props =>  (
  <Location>
    {({location}) => (
      <TransitionGroup>
        <Transition key={location.pathname} timeout={fadeDuration}>
            {state => (  
                <FadeTransitionStyled state={state}>
                    <Router location={location}>
                        {props.children}
                    </Router>
                </FadeTransitionStyled>
            )}
        </Transition>
      </TransitionGroup>
    )}
  </Location>
);

export default FadeTransition;