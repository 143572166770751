import styled from 'styled-components';
import Icon from 'components/Icon';

const AddToPlaylistStyled = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 60px;
    width: calc(100vw - 40px);
    max-width: 700px;
    border: 0;
    font-size: 1.125em;
    font-weight: 600;
    border-radius: 10px; 
    background: ${({active}) => active ? '#7a7e8b' : '#666974'};
    color: #fff;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: 200ms background ease-in;

    :hover {
        background: #7a7e8b;
    }

    .buttonText {
        margin-left: 10px
    }
`;

const AddToPlaylist = ({
    active
}) => {
    return (
        <AddToPlaylistStyled
            active={active}
            aria-label="Create a Playlist"
        >
            <Icon name="icon-create-playlist" width="21px" color="#E7E7E7"/>
            <span className="buttonText">Create a playlist</span>
        </AddToPlaylistStyled>
    );
};

export default AddToPlaylist;