import styled from 'styled-components';
import Icon from 'components/Icon';

const SubredditListComponent = styled.ul`
    display: block;
    overflow: auto;
    position: relative;
    border: 15px solid #36383F;
    border-radius: 0 0 5px 5px;
    border-bottom: 0;
    background: #36383F;
    scrollbar-width: thin;
    scrollbar-color: #888 #36383F;

    ::-webkit-scrollbar {
        width: 7px;
        border-radius: 3px;
        background: #888;
        background: none;
        transition: color .2s ease-in;
    }
        
    ::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background: #888;;
        transition: opacity .2s ease-in;

        :hover {
            background: #a3a3a3;
        }
    }

    .genre {
        font-weight: 600;
        color: #fff;
    }

    .genre-heading {
        position: sticky;
        top: 0;
        padding-bottom: 10px;
        background: #36383f;
        z-index: 1;
    }

    .genreList {
        display: flex;
        flex-wrap: wrap;
        padding: 15px 0;
    }

    .subreddit {
        margin: 0 15px 15px 0;
    }

    .simplebar-scrollbar::before {
        background: rgb(231 231 231 / 80%);
    }

    .toggleButton {
        padding: 13px 22px;
        font-size: .875rem;
        border: 0;
        border-radius: 8px;
        font-weight: 600;
        color: #9598A6;
        background: #2D2E35;
        cursor: pointer;
        

        &.is-active {
            padding: 13px;
            background: #3C96CE;
            color: #fff;
        }

        .checkIcon {
            position: relative;
            top: 2px;
            margin-right: 5px;
        }

        :focus {
            outline: none;
        }
        :hover:not(.is-active) {
            color: #fff;
            background: #575A65;
        }
    }
`;

const SubredditList = ({
    subredditsByGenre,
    active,
    onClickFn
}) => {
    return (
        <SubredditListComponent>
            {Object.keys(subredditsByGenre).map(genre => {
                return (
                    <li
                        key={`genre-${genre}`}
                        className="genre"
                    >
                        <div className="genre-heading">{genre}</div>
                        <ul className="genreList">
                            {subredditsByGenre[genre].map(subreddit => {
                                return (
                                    <li
                                        className="subreddit"
                                        key={`subreddit-${subreddit.url}`}
                                    >
                                        <button
                                            className={`toggleButton ${active(subreddit) ? 'is-active' : ''}`}
                                            onClick={() => onClickFn(subreddit)}
                                        >
                                            {active(subreddit) &&
                                                <Icon
                                                    name="icon-check-chip"
                                                    color="#fff"
                                                    width="13px"
                                                    className="checkIcon"
                                                />
                                            }
                                            
                                            {subreddit.name}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    </li>
                );
            })}
        </SubredditListComponent>
    );
};

export default SubredditList;