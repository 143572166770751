import React from 'react';
import ReactDOM from 'react-dom';
import {useState, useEffect, useRef, useCallback} from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useViewport } from 'hooks/useViewport';

import modalArrow from 'img/modal-arrow.svg';

const BlurReveal = keyframes `
    from {
        backdrop-filter: blur(0) opacity(0);
        background: rgba(0,0,0,0);
    }

    to {
        backdrop-filter: blur(5px) opacity(1);
        background: rgba(0,0,0,.2);
    }
`;
const BlurConceal = keyframes `
    from {
        backdrop-filter: blur(5px) opacity(1);
        background: rgba(0,0,0,.2);
    }

    to {
        backdrop-filter: blur(0) opacity(0);
        background: rgba(0,0,0,0);
    }
`;

const ContentReveal = keyframes `
    from {
        margin-top: -20px;
        opacity: 0;
    }

    to {
        margin-top: 20px;
        opacity: 1;
    }
`;
const ContentConceal = keyframes `
    from {
        margin-top: 20px;
        opacity: 1;
    }

    to {
        margin-top: -20px;
        opacity: 0;
    }
`;

const ArrowReveal = keyframes `
    from {
        transform: translate(-50%, -20px);
        opacity: 0;
    }

    to {
        transform: translate(-50%, 0px);
        opacity: 1;
    }
`;
const ArrowConceal = keyframes `
    from {
        transform: translate(-50%, 0);
        opacity: 1;
    }

    to {
        transform: translate(-50%, -20px);
        opacity: 0;
    }
`;

const DropdownButtonStyled = styled.div`
    position: relative;
    z-index: ${({visible}) => visible ? 2 : 9};
`;

const ModalButtonStyled = styled.div`
    position: absolute;
    z-index: 9;
    ${({getButtonStats}) => {
        return {
            left: getButtonStats().left,
            top: getButtonStats().top
        }
    }}
`;

const ArrowStyled = styled.div`
    position: absolute;
    ${({buttonStats}) => {
        return {
            left: buttonStats.left + (buttonStats.width / 2),
            top: buttonStats.bottom + 10
        }
    }}
    width: 20px;
    height: 13px;
    background: url(${modalArrow});
    animation: ${({active}) => active ? css`${ArrowReveal} .2s linear` :css`${ArrowConceal} .2s linear`};
    animation-fill-mode: forwards;
    z-index: 9;
`;

const DropdownContent = styled.div`
    display: flex;
    position: fixed;
    z-index: 9;
    ${({position, buttonStats, maxWidth, containerStats}) => {
        switch (position) {
            case 'left':
                return {
                    top: buttonStats.bottom,
                    left: buttonStats.left,
                    transform: `translateX(calc(-100% + ${buttonStats.width}px))`
                }
            case 'right':
                return {
                    top: buttonStats.bottom,
                    left: buttonStats.left,
                    
                }
            case 'max':
                const containerWidth = containerStats.width - 40 < maxWidth;

                if (containerWidth) {
                    return {
                        top: buttonStats.bottom,
                        left: `${containerStats.left + 20}px`
                    }
                } else {
                    return {
                        top: buttonStats.bottom,
                        left: buttonStats.left + (buttonStats.width / 2)  - (maxWidth / 2),
                    }
                }
            default:
                return {
                    left: buttonStats.left,
                    transform: `translateX(calc(-100% + ${buttonStats.width}px))`
                }
        }
    }};
    margin-top: 20px;
    width: 100%;
    max-height: ${({maxHeight}) => `${maxHeight}px`};
    max-width: ${({position, buttonStats, containerStats, maxWidth}) => {
        if (position === 'left') {
            const buttonPosition = containerStats.right - buttonStats.right;
            const availableWidth = containerStats.width - buttonPosition - 20;
            const width = availableWidth < maxWidth ? availableWidth : maxWidth;

            return `${width}px`;
        }

        if (position === 'right') {
            const buttonPosition = buttonStats.left - containerStats.left;
            const availableWidth = containerStats.width - buttonPosition - 20;
            const width = availableWidth < maxWidth ? availableWidth : maxWidth;

            return `${width}px`;
        }

        if (position === 'max') {
            const containerWidth = containerStats.width - 40;
            const width = containerWidth < maxWidth ? containerWidth : maxWidth;

            return `${width}px`;
        }
    }};
    animation: ${({active}) => {
        if (active) {
            return css`${ContentReveal} 200ms linear`;
        } else {
            return css`${ContentConceal} 200ms linear`;
        }
    }};
    animation-fill-mode: forwards;
`;

const BackgroundBlur = styled.div`
    position: fixed;
    top: 0;
    right: calc(50% - 50vw);
    width: 100vw;
    height: 100vh;
    animation: ${({active}) => active ? css`${BlurReveal} 200ms linear` : css`${BlurConceal} 200ms linear`};
    animation-fill-mode: forwards;
    z-index: 8;
`;


const Dropdown = ({
    opened,
    position,
    maxWidth,
    maxHeight,
    containerRef,

    button,
    content,

    setOpened
}) => {
    const [active, setActive] = useState(opened ? true : false);
    const { windowWidth, windowHeight } = useViewport();
    const dropdownButtonRef = useRef();
    const [containerMaxHeight, setContainerMaxHeight] = useState(maxHeight);
    const [buttonStats, setbuttonStats] = useState();
    const [containerStats, setContainerStats] = useState();
    const dropDownContentTop = 20;

    const getButtonStats = useCallback(() => {
        return dropdownButtonRef.current.getBoundingClientRect();
    });

    useEffect(() => {
        setTimeout(() => {
            setActive(opened);
        }, 200);
    }, [opened]);

    useEffect(() => {
        const newButtonStats = dropdownButtonRef.current.getBoundingClientRect();
        const newContainerStats = containerRef.current.getBoundingClientRect();
        const heightPadding = 10;

        setbuttonStats(newButtonStats);
        setContainerStats(newContainerStats);

        if (!maxHeight || maxHeight > (windowHeight - (newButtonStats.y + newButtonStats.height + heightPadding + dropDownContentTop))) {
            setContainerMaxHeight(windowHeight - (newButtonStats.y + newButtonStats.height + heightPadding + dropDownContentTop));
        }
        
    }, [windowWidth, windowHeight, opened]);


    return (
        <>
            <DropdownButtonStyled 
                ref={dropdownButtonRef}
                onClick={() => setOpened(prev => !prev)}
                visible={!opened}
            >
                {button}
            </DropdownButtonStyled>
            
            {ReactDOM.createPortal(
                <>
                    {active && containerStats && buttonStats &&
                        <>  
                            <ModalButtonStyled
                                buttonStats={buttonStats}
                                getButtonStats={getButtonStats}
                                onClick={() => setOpened(prev => !prev)}
                            >
                                {React.cloneElement(
                                    button,
                                    {active: true}
                                )}
                            </ModalButtonStyled>
                            
                            <ArrowStyled
                                active={opened}
                                buttonStats={buttonStats}
                            /> 
                            <DropdownContent
                                active={opened}
                                position={position}
                                maxHeight={containerMaxHeight}
                                maxWidth={maxWidth}
                                containerStats={containerStats}
                                buttonStats={buttonStats}
                            >
                                {content}
                            </DropdownContent>
                        </>
                    }
        
                    {active &&
                        <BackgroundBlur 
                            active={opened}
                            onClick={() => setOpened(prev => !prev)}
                        />
                    }
                </>,
                document.querySelector('#root')
            )}
        </>
    );
};

export default Dropdown;
