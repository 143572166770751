import { useState, useEffect, useRef, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from 'context/AppContext';
import { useViewport } from 'hooks/useViewport';

import PlaylistBuilderContainer from 'containers/PlaylistBuilderContainer';
import Player from 'components/playlist/player/Player';
import PlaylistMobile from 'components/playlist/mobile/Playlist';
import PlaylistDesktop from 'components/playlist/desktop/Playlist';
import Tags from 'components/playlist/Tags';
import ActionBar from 'components/playlist/ActionBar';
import SortBy from 'components/playlist/SortBy';
import Controls from 'components/playlist/Controls';

import Dropdown from 'components/Dropdown';
import AddToPlaylist from 'components/buttons/AddToPlaylist';
import TextIcon from 'components/buttons/TextIcon';

//const silence = require('../misc/silence.mp3');

const preloadImages = images => {
    const imagePromises = images.map(url => {
        return new Promise((res, rej) => {
            const img = new Image();
            const loaded = () => {
                res(url);
                img.removeEventListener('load', loaded);
            };

            img.addEventListener('load', loaded);
            img.addEventListener('error', loaded);
            img.src = url;
        });
    });

    return Promise.all(imagePromises);
};

const sortTracks = (tracks, sort, activeTrack) => {
    var sortedTracks;

    switch (sort.sortBy) {
        case 'title':
            sortedTracks = tracks.sort((a, b) => sortByName(a.track, b.track));
            break;
        case 'artist':
            sortedTracks = tracks.sort((a, b) => sortByName(a.artist, b.artist));
            break;
        case 'subreddit':
            sortedTracks = tracks.sort((a, b) => sortByName(a.subreddit, b.subreddit));
            break;
        case 'upvotes':
            sortedTracks = tracks.sort((a, b) => sortByNum(a.votes, b.votes));
            break;
        case 'time':
            sortedTracks = tracks.sort((a, b) => sortByNum(a.trackURL[0].length, b.trackURL[0].length));
            break;
        case 'shuffle':
            sortedTracks = tracks.sort((a, b) => sortByShuffle(a, b));
            if (activeTrack) {
                const activeTrackIndex = sortedTracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);
                sortedTracks.splice(activeTrackIndex, 1);
                sortedTracks.unshift(activeTrack);
            }
            break;
        default:
            sortedTracks = tracks.sort((a, b) => sortByNum(a.votes, b.votes));
    }

    return (sort.type === 'ascending' || sort.sortBy === 'shuffle') ? [...sortedTracks] : [...sortedTracks].reverse();

    function sortByNum(a, b) {
        return a - b;
    };

    function sortByName(a, b) {
        if (a > b) {
            return - 1;
        }

        if (a < b) {
            return 1;
        }

        return 0;
    };

    function sortByShuffle(a, b) {
        return .5 - Math.random();
    };
};


const PlaylistContainerStyled = styled.section`
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100vw;
    height: 100%;

    @media (min-width: 600px) {
        width: calc(100vw - 40px);
        max-height: 1050px;
        max-width: 900px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    
`;

const PlaylistContainer = () => {
    const {
        windowWidth,
        windowHeight
    } = useViewport();

    const {
        playlistSeed
    } = useContext(AppContext)

    const playerRef = useRef();
    const playlistContainerRef = useRef();

    const [tracks, setTracks] = useState([]);
    const [activeTrack, setActiveTrack] = useState(null);
    const [sort, setSort] = useState({
        sortBy: 'upvotes',
        type: 'descending'
    });

    const [playlistBuilderContainerOpened, setPlaylistBuilderContainerOpened] = useState(false);
    const [sortByDropdownState, setSortByDropdownState] = useState(false);

    const [backwardTime, setbackwardTime] = useState(null);
    const [controlsActive, setControlsActive] = useState(false);

    const [playing, setPlaying] = useState(false);
    const [playedProgress, setPlayedProgress] = useState({
        secondsInto: 0,
        totalSeconds: activeTrack ? activeTrack.length : 0,
        changedTime: null
    });
    const [volume, setvolume] = useState(100);
    const [repeat, setRepeat] = useState(false);

    /* 
        This variable is so that videos that error will skip to the next
        tracks in the direction (forward or backward) that someone would expect
    */
    const [playlistDirection, setPlaylistDirection] = useState('forward');

    const togglePlayFn = useCallback(
        () => {
            if (!activeTrack) setActiveTrack(() => tracks[0]);
            const state = !playing;

            console.log(state);

            if (state) {
                setPlaying(true);
            } else {
                setPlaying(false);
            }
        },
        [tracks, activeTrack, playing]);

    const changeTrackFn = useCallback(
        track => {
            
            if (track) {
                setActiveTrack(track);
                setControlsActive(() => false);
                setPlayedProgress(() => ({
                    secondsInto: 0,
                    totalSeconds: 1
                }));
            } else {
                setActiveTrack(false);
            }
        },
        []);

    const forwardFn = useCallback(
        () => {
            const activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);

            if (activeTrackIndex === tracks.length - 1) {
                const totalSeconds = playerRef.current.getDuration();
                setPlaying(() => false);
                setPlayedProgress(() => ({
                    secondsInto: totalSeconds,
                    totalSeconds: totalSeconds
                }));
                playerRef.current.seekTo(totalSeconds);

            } else {
                changeTrackFn(tracks[tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId) + 1]);
            }

            setPlaylistDirection('forward');
        },
        [tracks, activeTrack]);

    const backwardFn = useCallback(
        () => {
            const currentTime = performance.now();
            const activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);

            if (backwardTime === null || currentTime - backwardTime > 1000 || activeTrackIndex === 0) {
                playerRef.current.seekTo(0);
                setPlayedProgress(prev => ({
                    secondsInto: 0,
                    totalSeconds: prev.totalSeconds
                }));
                setPlaying(() => true);
                setbackwardTime(() => currentTime);
            } else {
                changeTrackFn(tracks[tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId) - 1]);
                setbackwardTime(() => null);
            }

            setPlaylistDirection('backward');
        },
        [tracks, activeTrack, playedProgress]);

    const trackEndFn = useCallback(
        () => {
            if (repeat) {
                playerRef.current.seekTo(0);
            } else {
                forwardFn();
            }
        },
        [repeat, forwardFn, playerRef]);

    const trackEndErrorFn = useCallback(
        () => {
            const activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId);

            if (playlistDirection === 'forward') {
                if (activeTrackIndex !== tracks.length - 1) {
                    forwardFn();
                }
            } else {
                if (activeTrackIndex !== 0) {
                    changeTrackFn(tracks[tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId) - 1]);
                } else {
                    forwardFn();
                }
            }

            // TODO: Show error popup here
        },
        [tracks, playlistDirection, activeTrack]);

    const onProgressFn = useCallback(
        progress => {
            setPlayedProgress(prev => {
                if (!prev.changedTime || progress.changedTime - prev.changedTime >= 1000) {
                    return progress;
                } else {
                    return prev;
                }
            });
        }, []);

    const seekToFn = useCallback(
        seconds => {
            playerRef.current.seekTo(seconds);
            setPlayedProgress(prev => ({
                secondsInto: seconds,
                totalSeconds: prev.totalSeconds,
                changedTime: performance.now()
            }));
            setPlaying(() => true);
        },[playerRef]);

    useEffect(() => {
        setTracks([]);

        if (!playlistSeed.subreddits.length && playlistSeed.timeFrame.length) return;

        fetch(`/api/getTracks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                addedSubreddits: playlistSeed.subreddits,
                selectedTimeFrame: playlistSeed.timeFrame
            })
        })
            .then(res => res.json())
            .then(results => {
                if (results.length) {
                    const tracksList = results;
                    const trackIndex = activeTrack && tracksList.findIndex(track => track.spotifyId === activeTrack.spotifyId) || 0;

                    var preloadTotal = (windowWidth < 850) ? windowHeight / 115 : windowHeight / 45;
                    var preloadStart, preloadEnd;

                    if ((tracksList.length - 1 - trackIndex) >= preloadTotal) {
                        preloadStart = trackIndex;
                        preloadEnd = trackIndex + preloadTotal;
                    } else {
                        preloadEnd = tracksList.length - 1;
                        preloadStart = trackIndex - (preloadTotal - (tracksList.length - 1 - trackIndex));
                    }

                    preloadImages(
                        tracksList
                            .slice(preloadStart, preloadEnd)
                            .map(track => track.albumArt[1])
                    ).then(() => {
                        setTracks(sortTracks(tracksList, sort, activeTrack));
                    });
                }
            });
    }, [playlistSeed]);

    useEffect(() => {
        setTracks(prev => sortTracks(prev, sort, activeTrack));
    }, [sort]);

    useEffect(() => {
        const handleKeyBindings = ({ code }) => {
            if (code === 'Space') {
                togglePlayFn();
            }
        };

        document.addEventListener('keypress', handleKeyBindings);

        return () => document.removeEventListener('keypress', handleKeyBindings);
    }, [togglePlayFn]);

    useEffect(() => {
        
    }, [playedProgress])

    return (
        <PlaylistContainerStyled
            ref={playlistContainerRef}
            windowWidth={windowWidth}
        >
            <Player
                tracks={tracks}
                activeTrack={activeTrack}
                playerRef={playerRef}
                playing={playing}
                volume={volume}
                windowWidth={windowWidth}
                windowHeight={windowHeight}

                setPlaying={setPlaying}
                setControlsActive={setControlsActive}
                togglePlayFn={togglePlayFn}
                trackEndFn={trackEndFn}
                trackEndErrorFn={trackEndErrorFn}
                onProgressFn={onProgressFn}
            />
            <ActionBar
                topContent={
                    <>
                        <Tags
                            subreddits={playlistSeed.subreddits.map(subreddit => subreddit.name)}
                        />

                        <Dropdown
                            opened={playlistBuilderContainerOpened}
                            position='left'
                            maxWidth='500'
                            maxHeight='600'
                            containerRef={playlistContainerRef}
                            setOpened={setPlaylistBuilderContainerOpened}
                            // don't make a function every time
                            button={<AddToPlaylist/>}
                            content={<PlaylistBuilderContainer action={setPlaylistBuilderContainerOpened} />}
                        />
                    </>
                }
                bottomContent={
                    (windowWidth < 600) &&
                    <Dropdown
                        opened={sortByDropdownState}
                        position='left'
                        maxWidth='500'
                        containerRef={playlistContainerRef}
                        setOpened={setSortByDropdownState}
                        button={<TextIcon buttonText="Sort by" action={() => setSortByDropdownState(prev => !prev)} />}
                        content={
                            <SortBy
                                sort={sort}

                                setSort={setSort}
                                setSortByDropdownState={setSortByDropdownState}
                            />
                        }
                    />

                }
            />
            {windowWidth < 600 ?
                <PlaylistMobile
                    tracks={tracks}
                    activeTrack={activeTrack}
                    playing={playing && controlsActive}
                    windowHeight={windowHeight}
                    changeTrackFn={changeTrackFn}
                    togglePlayFn={togglePlayFn}
                    setPlaying={setPlaying}
                />
                :
                <PlaylistDesktop
                    tracks={tracks}
                    activeTrack={activeTrack}
                    playing={playing && controlsActive}
                    windowHeight={windowHeight}
                    changeTrackFn={changeTrackFn}
                    togglePlayFn={togglePlayFn}
                    sort={sort}
                    setSort={setSort}
                />
            }
            <Controls
                timeInto={playedProgress.secondsInto}
                totalSeconds={playedProgress.totalSeconds}
                progress={playedProgress.percent}
                volume={volume}
                playing={playing}
                activeTrack={activeTrack}
                controlsActive={controlsActive}
                sort={sort}
                setSort={setSort}
                repeat={repeat}
                setRepeat={setRepeat}

                seekToFn={seekToFn}
                togglePlayFn={togglePlayFn}
                forwardFn={forwardFn}
                backwardFn={backwardFn}
                setVolume={setvolume}
            />
        </PlaylistContainerStyled>
    );
};


export default PlaylistContainer;