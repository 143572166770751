import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Icon from 'components/Icon';
import AlbumArt from 'components/AlbumArt';

const getTimefromMS = timeMS => {
    const duration = moment.duration(timeMS);
    return `${duration.minutes()}:${duration.seconds().toString().padEnd(2, '0')}`;
}

const TrackContainerStyled = styled.div `
    ${props => props.styles};
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 15px 15px 15px 15px;
    font-size: 0.875em;
    background: ${props => props.active ? '#33353D' : 'none'};
    transition: 100ms background ease-in;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    :hover {
        background: ${props => !props.active ? '#2a2b31' : '#33353D'};

        .trackDetailsLeft .subreddit a {
            text-decoration: underline;
        }
    }

    .trackDetailsLeft {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 5px;
        min-width: 0;
        overflow: hidden;

        .title {
            margin-bottom: 10px;
            padding-bottom: 1px;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: ${props => props.active ? '#3C96CE' : '#BBBBBB'};
            transition: 200ms color ease-in;
        }
    
        .artist {
            padding-bottom: 1px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            color: ${props => props.active ? '#3C96CE' : '#BBBBBB'};
            transition: 200ms color ease-in;
        }
    
        .subreddit {
            margin-top: auto;
            font-weight: 600;
            color: ${props => props.active ? '#ACACAC' : '#666974'};
            transition: 200ms color ease-in;

            a {
                display: inline-flex;
                align-items: center;
                color: inherit;
                text-decoration: none;

                :visited {
                    color: inherit;
                }

                :hover {
                    color: #BBBBBB;
                }
            }
        }
    }

    .trackDetailsRight {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;

        .time, .votes {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 5px 0;
            position: relative;
            width: 55px;
            text-align: right;
            color: ${props => props.active ? '#ACACAC' : '#666974'};
            transition: 200ms color ease-in;
        }
    }
`;


const TrackContainer = ({
    isActive,
    track,
    playing,
    clickFn,
}) => ( 
    <TrackContainerStyled 
        onClick={() => clickFn(track)}
        active={isActive}
    >
        <AlbumArt 
            details = {{
                album: track.albumTitle,
                url: track.albumArt[1],
                background: track.albumArtGradient
            }}
            transitionTime='200ms'
            playing={playing}
            active={isActive}
            size='big'
        />
        <div className="trackDetailsLeft">
            <div className="title"> {track.track}</div> 
            <div className="artist"> {track.artist} </div> 
            <div className="subreddit"> 
                <a href={`https://www.reddit.com${track.redditURL}`} target="_blank" onClick={e => e.stopPropagation()}>{track.subreddit} </a>
            </div> 
        </div> 
        <div className="trackDetailsRight">
            <div className="time" >
                <Icon name="icon-time"
                    width="13px"
                    height="13px"
                    color={isActive ? '#ACACAC' : '#666974'}
                /> 
                {getTimefromMS(track.trackURL[0].length)} 
            </div > 
            <div className="votes">
                <Icon 
                    name="icon-upvote"
                    width="13px"
                    height="13px"
                    color={isActive ? '#ACACAC' : '#666974'}
                /> 
                {track.votes} 
            </div> 
        </div> 
    </TrackContainerStyled>
);

export default TrackContainer;