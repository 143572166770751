import styled from 'styled-components';
import moment from 'moment';
import AlbumArt from 'components/AlbumArt';
import Icon from 'components/Icon';

const getTimefromMS = timeMS => {
    const duration = moment.duration(timeMS);
    return `${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
}

const TrackContainerStyled = styled.div `
    ${props => props.styles};
    display: flex;
    flex-direction: row;
    width: calc(100% - 10px);
    height: 45px;
    font-size: 0.875em;
    
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    :hover {
        .trackDetails {
            background: ${({active}) => !active ? '#575A65' : '#3C96CE'};
            color: ${({active}) => active ? '#fff' : '#d8d8d8'};
        }

        .albumArt {
            .hoverBackground {
                opacity: ${({active}) => active ? 0 : 1}
            }
        }
        .subreddit {
            .icon-link {
                opacity: 1;
            }
        }
    }
    .albumArt {
        position: relative;

        :hover {
            .icon-play {
                fill: #fff;
            }
        }

        .hoverBackground {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(2,0,36,.9) 0%, rgba(255,255,255,0) 100%);
            border-radius: 5px;
            z-index: 1;
            opacity: 0;
            transition: opacity 200ms ease-in;
        }

        .icon-play {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .trackDetails {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-left: 10px;
        padding: 10px;
        width: 100%;
        // https://css-tricks.com/flexbox-truncated-text/
        min-width: 0;
        border-radius: 5px;
        color: ${({active}) => active ? '#fff' : '#B5B6B9'};
        font-weight: ${({active}) => active ? 600 : 400};
        background: ${({index, active}) => {
            if (active) return '#3C96CE';
            if (index === 'even') return '#36383F';
            if (index === 'odd') return '#292A30';
        }};
        transition: 200ms all ease-in;
    }

    .title, .artist, .subreddit, .votes, .time {
        padding-bottom: 1px;
        align-items: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .title, .artist, .subreddit {
        flex: 1 1 33%;
        text-align: left;
    }

    .votes, .time {
        flex: 0 0 50px;
    }

    .subreddit {
        a {
            display: inline-flex;
            align-items: center;
            color: inherit;
            text-decoration: none;

            :visited {
                color: inherit;
            }

            :hover {
                color: #fff;

                .icon-link {
                    fill: #fff;
                }
            }

            .icon-link {
                opacity: 0;
                margin-right: 5px;
                transition: opacity 200ms ease-in;
                fill: ${({active}) => active ? '#fff' : '#B1B2B5'}
            }
        }
    }
`;


const TrackContainer = ({
    isActive,

    track,
    index,
    playing,
    clickFn
}) => ( 
    <TrackContainerStyled 
        onClick={() => clickFn(track)}
        active={isActive}
        index={(index % 2) ? 'even' : 'odd'}
    >
        <div className="albumArt">
            <div className="hoverBackground">
                <Icon 
                    name="icon-play"
                    color="#d8d8d8"
                    width="16px"
                    className="icon-play"
                    transitionMS="0ms"
                />
            </div>
            
            <AlbumArt
            details = {{
                album: track.albumTitle,
                url: track.albumArt[1],
                background: track.albumArtGradient
            }}
                transitionTime='200ms'
                playing={playing}
                active={isActive}
                size='small'
            />
        </div>
       
       <div className="trackDetails">
            <div className="title"> {track.track} </div>
            <div className="artist"> {track.artist} </div>
            <div className="subreddit"> 
                <a href={`https://www.reddit.com${track.redditURL}`} target="_blank" onClick={e => e.stopPropagation()}>
                    <Icon 
                        name="icon-link"
                        color="#B1B2B5"
                        hover="#fff"
                        width="16px"
                        className="icon-link"
                        transitionMS="0ms"
                    />
                    {track.subreddit}
                </a> 
            </div>
            <div className="votes"> {track.votes} </div>
            <div className="time"> {getTimefromMS(track.trackURL[0].length)}</div>
       </div>
        
    </TrackContainerStyled>
);

export default TrackContainer;