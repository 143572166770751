import { useState, useEffect, useRef, memo } from 'react';
import styled from 'styled-components';

import PlayerBackground from 'components/playlist/player/PlayerBackground';
import MediaPlayer from 'components/playlist/player/MediaPlayer';

const PlayerStyled = styled.section `
    position: relative;
    height: 30%;
    max-height: 300px;
    min-height: 160px;
    border-radius: 15px 15px 0 0;
    overflow: hidden;
`;

const Player = ({
    tracks,
    activeTrack,
    playerRef,
    playing,
    volume,
    windowWidth,
    windowHeight,
    hijackMediaSession,

    setPlaying,
    setControlsActive,
    trackEndFn,
    trackEndErrorFn,
    onProgressFn
}) => {
    const [albumAmount, setAlbumAmount] = useState(6);
    const [albums, setAlbums] = useState([]);
    const [playerHeight, setPlayerHeight] = useState();

    const playerContainerRef = useRef();

    useEffect(() => {
        var {height, width} = playerContainerRef.current.getBoundingClientRect();
        height -= 30;

        if ((height * 1.7778) > width) {
            setPlayerHeight(width / 1.7778 - 30);
        } else {
            setPlayerHeight(height - 30);
        }

        if (windowWidth > 370) setAlbumAmount(9);
        if (windowWidth > 500) setAlbumAmount(12);
        if (windowWidth > 650) setAlbumAmount(15);
        if (windowWidth > 750) setAlbumAmount(18);
    }, [windowWidth, windowHeight]);

    useEffect(() => {
        var activeTrackIndex, start, end;

        if (activeTrack) {
            activeTrackIndex = tracks.findIndex(track => track.spotifyId === activeTrack.spotifyId) + 1;
        } else {
            activeTrackIndex = 0;
        }

        if (tracks.length < albumAmount) {
            start = 0;
            end = tracks.length;
        } else {
            if ((activeTrackIndex + albumAmount) > tracks.length) {
                start = tracks.length - albumAmount;
                end = tracks.length;
            } else {
                start = activeTrackIndex;
                end = activeTrackIndex + albumAmount;
            }
        }
        

        setAlbums(
            tracks
                .slice(start, end)
                .map(track => ({
                    spotifyId: track.spotifyId,
                    gradient: track.albumArtGradient,
                    lowRes: track.albumArt[1],
                    highRes: track.albumArt[0]
                }))
        );
    }, [tracks, activeTrack]);

    return (
        <PlayerStyled
            ref={playerContainerRef}
            playerHeight={playerHeight}
        >
            
            {activeTrack &&
                <MediaPlayer
                    volume={volume}
                    activeTrack={activeTrack}
                    playerRef={playerRef}
                    playing={playing}
                    trackEndFn={trackEndFn}
                    trackEndErrorFn={trackEndErrorFn}
                    onProgressFn={onProgressFn}
                    setPlaying={setPlaying}
                    setControlsActive={setControlsActive}
                    playerHeight={playerHeight}
                    hijackMediaSession={hijackMediaSession}
                />
            }

            <PlayerBackground
                albums={albums}
            />
            
        </PlayerStyled>
    )
};

export default memo(Player);